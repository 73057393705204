import {
  BrowserRouter,
  Outlet,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from 'react-router-dom';
import NotificationToast from '@containers/NotificationToast';
import React from 'react';
import MainFlowLayout from '@containers/MainFlowLayout';
import { additionalRoutes, mainFlowRoutes } from '@constants/routes';
import useNotificationContext from '@hooks/useNotificationContext';
import HeadComponent from '@components/HeadComponent';
import { setupInterceptors } from '@utils/API';
import NotFoundFallback from '@pages/NotFoundFallback';
import useQuery from '@hooks/useQuery';
import i18next from 'i18next';
import AdminDashboard from '@pages/AdminDashboard';
import AdminLogin from '@pages/AdminLogin';
import { ContractProvider } from '@context/Contract';
import AdminPreview from '@pages/AdminPreview';
import AuthRedirect from '@pages/AuthRedirect';
import renderRoutes from './renderRoutes';

const ResetPage = () => {
  const { pathname } = useLocation();
  const { setNotification } = useNotificationContext();

  React.useEffect(() => {
    window.scrollTo(0, 0);
    setNotification({ text: '', visible: false });
  }, [setNotification, pathname]);

  return null;
};

const SetupInterceptors = () => {
  const navigate = useNavigate();
  setupInterceptors(navigate);
  return null;
};

const LanguageHandler = () => {
  const query: URLSearchParams = useQuery();
  const lang = query.get('lang');

  React.useEffect(() => {
    if (!lang) return;
    i18next.changeLanguage(lang);
    localStorage.setItem('lng', lang);
  }, [lang]);

  return null;
};

const OnboardingContext = () => {
  return (
    <ContractProvider>
      <Outlet />
    </ContractProvider>
  );
};

const RouterContent = () => {
  return (
    <>
      <HeadComponent />
      <SetupInterceptors />
      <NotificationToast />
      <ResetPage />
      <LanguageHandler />
      <Routes>
        <Route element={<OnboardingContext />}>
          {renderRoutes({ routes: additionalRoutes })}
          <Route element={<MainFlowLayout />}>
            {renderRoutes({ routes: mainFlowRoutes })}
          </Route>
        </Route>
        <Route>
          <Route path="admin/login" element={<AdminLogin />} />
          <Route path="admin/dashboard" element={<AdminDashboard />} />
          <Route
            path="admin/preview/:id"
            element={
              <ContractProvider>
                <AdminPreview />
              </ContractProvider>
            }
          />
        </Route>
        <Route path="auth/redirect" element={<AuthRedirect />} />
        <Route path="*" element={<NotFoundFallback />} />
      </Routes>
    </>
  );
};

const Router = () => {
  return (
    <BrowserRouter>
      <RouterContent />
    </BrowserRouter>
  );
};

export default Router;
