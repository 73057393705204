import {
  Box,
  colors,
  ContentText,
  fontWeights,
  Heading,
  lineHeights,
} from '@fortum/elemental-ui';
import SubpageLayout from '@containers/SubpageLayout';
import { ClickableQuestionItem } from '@components/QuestionItem';
import { useTranslation } from 'react-i18next';

interface QuestionItemProps {
  image: string;
  text: string;
  children: React.ReactNode;
}

const CustomQuestionItem: React.FC<QuestionItemProps> = ({
  image,
  text,
  children,
}) => (
  <ClickableQuestionItem
    containerProps={{ marginBottom: '1rem', padding: '1rem' }}
    image={image}
    text={text}
  >
    {children}
  </ClickableQuestionItem>
);

function FAQBills() {
  const contentfulCategory = 'faq';
  const { t } = useTranslation();

  const questions = t<string, { title: string; image: string; text: string }[]>(
    `${contentfulCategory}.question`,
    {
      returnObjects: true,
    }
  );
  return (
    <SubpageLayout
      header={t(`${contentfulCategory}.header`)}
      headerTextStyle={{ marginLeft: '2rem' }}
    >
      <Box margin="1rem 0 0">
        <Heading
          weight={fontWeights.bold}
          lineHeight={lineHeights.tight}
          color={colors.inkGrey}
          marginBottom="0.5rem"
        >
          {t(`${contentfulCategory}.title`)}
        </Heading>
        <ContentText size="l" color={colors.emperorGrey} marginBottom="2rem">
          {t(`${contentfulCategory}.description`)}
        </ContentText>
        {Array.isArray(questions)
          ? questions.map(({ title, image, text }) => (
              <CustomQuestionItem image={image} text={text} key={title}>
                {title}
              </CustomQuestionItem>
            ))
          : null}
      </Box>
    </SubpageLayout>
  );
}

export default FAQBills;
