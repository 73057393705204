import { adminLogin } from '@utils/API';
import React from 'react';

interface AdminContextType {
  authToken: string;
  setAuthToken: React.Dispatch<React.SetStateAction<string>>;
  authorizeAdmin: (props: {
    username: string;
    password: string;
  }) => Promise<boolean>;
  loading: boolean;
  logout: () => void;
  tokenSet: boolean;
}

export const AdminContext = React.createContext<AdminContextType>(
  {} as AdminContextType
);

export function AdminProvider({
  children,
}: {
  children: React.ReactNode;
}): JSX.Element {
  const [authToken, setAuthToken] = React.useState<string>('');
  const [loading, setLoading] = React.useState<boolean>(true);
  const [tokenSet, setTokenSet] = React.useState<boolean>(false);
  const STORAGE_KEY = 'admin_token';

  React.useEffect(() => {
    const foundToken = localStorage.getItem(STORAGE_KEY) as string;
    if (foundToken) setAuthToken(foundToken);
    setTokenSet(true);
  }, []);

  const authorizeAdmin = React.useCallback(
    async ({ username, password }: { username: string; password: string }) => {
      try {
        const result = await adminLogin({ username, password });
        if (!result.token) return false;
        setAuthToken(result.token);
        localStorage.setItem(STORAGE_KEY, result.token);
        return true;
      } catch (error) {
        setAuthToken('');
        return false;
      } finally {
        setLoading(false);
      }
    },
    []
  );

  const logout = () => {
    setAuthToken('');
    localStorage.removeItem(STORAGE_KEY);
  };

  const memoizedValue = React.useMemo(
    () => ({
      authToken,
      setAuthToken,
      authorizeAdmin,
      loading,
      logout,
      tokenSet,
    }),
    [authToken, setAuthToken, authorizeAdmin, loading, tokenSet]
  );

  return (
    <AdminContext.Provider value={memoizedValue}>
      {children}
    </AdminContext.Provider>
  );
}
