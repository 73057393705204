import {
  Box,
  ContentText,
  IconCheck,
  IconLanguage,
  Overlay,
  colors,
} from '@fortum/elemental-ui';
import useNotificationContext from '@hooks/useNotificationContext';
import i18next from 'i18next';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

type Props = {
  disableLoading?: boolean;
  color?: string;
};

const LanguageItem = styled(Box)`
  display: flex;
  justify-content: space-between;
  padding: 0 1rem;
  &:hover {
    background-color: ${colors.lightGrey};
    cursor: pointer;
  }
`;

const LanguageSwitcher: React.FC<Props> = ({
  disableLoading = false,
  color,
}) => {
  const [current, setCurrent] = React.useState(i18next.language);
  const [loading, setLoading] = React.useState(false);
  const { setNotification } = useNotificationContext();
  const { t } = useTranslation();
  const [expanded, setExpanded] = useState(false);
  const languages =
    process.env.REACT_APP_ENVIRONMENT === 'prod'
      ? [
          { key: 'en-US', shortcut: 'EN', label: t('languages.english') },
          { key: 'fi-FI', shortcut: 'FI', label: t('languages.finnish') },
          { key: 'sv-FI', shortcut: 'SV', label: t('languages.swedish') },
        ]
      : [
          { key: 'en-US', shortcut: 'EN', label: t('languages.english') },
          { key: 'fi-FI', shortcut: 'FI', label: t('languages.finnish') },
          { key: 'sv-FI', shortcut: 'SV', label: t('languages.swedish') },
          { key: 'nb-NO', shortcut: 'NO', label: t('languages.norwegian') },
        ];

  const onLanguageChange = async (lang: string) => {
    setLoading(true);
    try {
      await i18next.changeLanguage(lang);
      localStorage.setItem('lng', lang);
      setCurrent(lang);
    } catch (error) {
      setNotification({
        visible: true,
        text: t('errors.cannotChangeLanguage'),
        icon: 'IconError',
        color: colors.punchRed,
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      margin="0"
      marginBottom="0.5rem"
      position="relative"
    >
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        onClick={() => setExpanded((prev) => !prev)}
        cursor="pointer"
      >
        <IconLanguage color={color} />

        <ContentText ml="0.5rem" color={color}>
          {languages.find((lang) => lang.key === current)?.shortcut}
        </ContentText>
      </Box>
      {expanded && (
        <Box
          position="absolute"
          backgroundColor={colors.snowWhite}
          width="12rem"
          top="120%"
          right="-14px"
          boxShadow="0px 0px 27px 8px rgba(0, 0, 0, 0.10)"
          zIndex={1001}
        >
          {languages.map((lang) => (
            <LanguageItem
              backgroundColor={
                current === lang.key ? colors.lightGrey : colors.snowWhite
              }
              onClick={() => onLanguageChange(lang.key)}
            >
              <ContentText padding="1rem 1rem">{lang.label}</ContentText>
              {current === lang.key ? <IconCheck /> : null}
            </LanguageItem>
          ))}
        </Box>
      )}
      {loading && !disableLoading ? (
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        <Overlay opened onClose={() => {}} />
      ) : null}
    </Box>
  );
};

export default LanguageSwitcher;
