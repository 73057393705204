import {
  Box,
  colors,
  ContentText,
  fontWeights,
  lineHeights,
} from '@fortum/elemental-ui';
import FormattedEnhancedMessage from '@utils/intl-extenders/FormattedEnhancedMessage';
import BottomModal from '@containers/BottomModal';

interface Props {
  open: boolean;
  productTitle: string;
  productName: string;
  onClose: () => void;
}

function ReadMoreContractModal({
  open,
  productTitle,
  productName,
  onClose,
}: Props) {
  return (
    <BottomModal show={open} setShow={onClose} showCloseButton>
      <Box margin="1rem 0">
        <Box
          display="flex"
          flexDirection="column"
          alignItems="flex-start"
          justifyContent="space-between"
          marginBottom="1.5rem"
        >
          <ContentText size="xl" lineHeight={lineHeights.tight}>
            {productTitle}
          </ContentText>
        </Box>
        <ContentText
          color={colors.inkGrey}
          weight={fontWeights.light}
          lineHeight={lineHeights.loose}
        >
          <FormattedEnhancedMessage
            i18nKey={`products.${productName}.descriptionLong`}
          />
        </ContentText>
      </Box>
    </BottomModal>
  );
}

export default ReadMoreContractModal;
