import React from 'react';

interface Props {
  redirectPath?: string;
  children: React.ReactElement;
}

const ProtectedRoute: React.FC<Props> = ({ redirectPath = '/', children }) => {
  // eslint-disable-next-line no-console
  console.log(redirectPath);
  // const { authToken, verify, loading } = useAppContext();

  // React.useEffect(() => {
  //   verify();
  // }, [verify]);

  // if (loading) return <Spinner />;

  // if (!authToken) {
  //   return <Navigate to={redirectPath} replace />;
  // }

  return children || <div>Unauthorized</div>;
};

export default ProtectedRoute;
