import { Box, DatePicker } from '@fortum/elemental-ui';

interface Props {
  onFromChange: (from: Date | null) => void;
  onToChange: (to: Date | null) => void;
  fromValue: Date | null;
  toValue: Date | null;
}

const DateRangeFilter = ({
  onFromChange,
  onToChange,
  fromValue,
  toValue,
}: Props) => {
  return (
    <Box display="flex">
      <DatePicker
        locale="en"
        name="from"
        label="From"
        onChange={onFromChange}
        selected={fromValue || undefined}
      />
      <DatePicker
        locale="en"
        name="to"
        label="To"
        onChange={onToChange}
        selected={toValue || undefined}
      />
    </Box>
  );
};

export default DateRangeFilter;
