import { matchRoutes, useLocation, RouteObject } from 'react-router-dom';

const useCurrentPath = ({ routes }: { routes: RouteObject[] }) => {
  const location = useLocation();
  const matches = matchRoutes(routes, location);
  if (!matches) return null;

  return matches[0].route.path;
};

export default useCurrentPath;
