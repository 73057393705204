import { Helmet } from 'react-helmet-async';
import { useLocation } from 'react-router-dom';

const gtmScript = `(function (w, d, s, l, i) {
  w[l] = w[l] || [];
  w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });
  var f = d.getElementsByTagName(s)[0],
  j = d.createElement(s),
  dl = l != 'dataLayer' ? '&l=' + l : '';
  j.async = true;
  j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
  f.parentNode.insertBefore(j, f);
  })(
  window,
  document,
  'script',
  'dataLayer',
  '${process.env.REACT_APP_GOOGLE_TAG_MANAGER_ID}'
  );`;

function AdminHead() {
  return (
    <Helmet>
      <script>{gtmScript}</script>
    </Helmet>
  );
}

function UserHead() {
  if (process.env.REACT_APP_ENVIRONMENT === 'prod')
    return (
      <Helmet>
        {/* <!-- OneTrust Cookies Consent Notice start for fortum.fi */}
        <script
          type="text/javascript"
          src="https://cdn.cookielaw.org/consent/6ddfcee2-a6f8-4ef8-8660-4039d60e944f/OtAutoBlock.js"
        />
        <script
          src="https://cdn.cookielaw.org/scripttemplates/otSDKStub.js"
          data-document-language="true"
          type="text/javascript"
          charSet="UTF-8"
          data-domain-script="6ddfcee2-a6f8-4ef8-8660-4039d60e944f"
        />
        <script type="text/javascript">{`function OptanonWrapper() {}`}</script>
        {/* OneTrust Cookies Consent Notice end for fortum.fi */}
        <script>{gtmScript}</script>
      </Helmet>
    );

  return (
    <Helmet>
      <script>{gtmScript}</script>
    </Helmet>
  );
}

function HeadComponent() {
  const { pathname } = useLocation();
  const isAdmin = pathname.includes('admin');

  return isAdmin ? <AdminHead /> : <UserHead />;
}

export default HeadComponent;
