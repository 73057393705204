import {
  Box,
  BoxProps,
  colors,
  IconCross,
  Overlay,
} from '@fortum/elemental-ui';
import ReactDOM from 'react-dom';

interface Props {
  show: boolean;
  setShow?: React.Dispatch<React.SetStateAction<boolean>>;
  showCloseButton?: boolean;
  contentProps?: BoxProps;
  children: React.ReactNode;
}

const CloseButton = ({ onClick }: { onClick: () => void }) => {
  return (
    <Box
      borderRadius="50%"
      backgroundColor={colors.snowWhite}
      width="3.5rem"
      height="3.5rem"
      position="absolute"
      top="-5.5rem"
      left={0}
      right={0}
      marginLeft="auto"
      marginRight="auto"
      display="flex"
      justifyContent="center"
      cursor="pointer"
      onClick={onClick}
    >
      <IconCross color={colors.inkGrey} size="2rem" />
    </Box>
  );
};

const BottomModal: React.FC<Props> = ({
  show,
  showCloseButton,
  setShow,
  contentProps,
  children,
}) => {
  return ReactDOM.createPortal(
    <Overlay
      opened={!!show}
      onClose={() => {
        if (setShow) setShow(false);
      }}
      style={{ opacity: show ? 1 : 0 }}
    >
      <Box
        backgroundColor={colors.snowWhite}
        position="absolute"
        left={0}
        bottom={show ? 0 : '-100%'}
        padding="2rem 2rem 1rem"
        borderRadius="20px 20px 0 0"
        transition="bottom 1.5s"
        zIndex={100}
        width="100%"
        {...contentProps}
      >
        {showCloseButton && (
          <CloseButton
            onClick={() => {
              if (setShow) setShow(false);
            }}
          />
        )}
        {children}
      </Box>
    </Overlay>,
    document.body
  );
};

export default BottomModal;
