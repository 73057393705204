import {
  addAddon as addAddonBase,
  terminateAddon as terminateAddonBase,
  joinLoyalty as joinLoyaltyBase,
  requestPincode,
  activateEInvoice as activateEInvoiceBase,
  submitFlow as submitFlowBase,
  changeDeliveryAddress as changeDeliveryAddressBase,
  verifyOrder as verifyOrderBase,
  redirectOrder as redirectOrderBase,
} from '@utils/API';
import useAppContext from './useAppContext';

function useApi() {
  const { authToken } = useAppContext();

  const addAddon = (templateId?: string | number) =>
    addAddonBase(authToken, templateId);
  const terminateAddon = (terminateTemplateNo?: string, tarrifNo?: number) =>
    terminateAddonBase(authToken, terminateTemplateNo, tarrifNo);
  const joinLoyalty = () => joinLoyaltyBase(authToken);
  const activateEInvoice = (bankId: string) =>
    activateEInvoiceBase(authToken, bankId);
  const submitFlow = () => submitFlowBase(authToken);
  const changeDeliveryAddress = ({
    zipcode,
    streetAddress,
  }: {
    zipcode: string;
    streetAddress: string;
  }) => changeDeliveryAddressBase({ authToken, zipcode, streetAddress });

  const verifyOrder = ({ code }: { code: string }) =>
    verifyOrderBase({ authToken, code });

  const redirectOrder = () => redirectOrderBase({ authToken });
  return {
    addAddon,
    terminateAddon,
    joinLoyalty,
    requestPincode,
    activateEInvoice,
    submitFlow,
    changeDeliveryAddress,
    verifyOrder,
    redirectOrder,
  };
}

export default useApi;
