import useAppContext from '@hooks/useAppContext';
import { useCustomerDetailsQuery } from '@utils/API';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { ContractDetails } from '../../types/api';

interface ContractContextType {
  contractDetails?: ContractDetails;
  isLoading: boolean;
  isError: boolean;
  isIdle: boolean;
  setContractDetails: React.Dispatch<
    React.SetStateAction<ContractDetails | undefined>
  >;
}

export const ContractContext = React.createContext<ContractContextType>(
  {} as ContractContextType
);

export function ContractProvider({
  children,
}: {
  children: React.ReactNode;
}): JSX.Element {
  const { authToken } = useAppContext();
  const location = useLocation();
  const { data, isLoading, isIdle, isError } = useCustomerDetailsQuery(
    authToken,
    !location.pathname.includes('admin')
  );

  const [contractDetails, setContractDetails] = useState<
    ContractDetails | undefined
  >(data);

  useEffect(() => {
    setContractDetails(data);
  }, [data]);

  const memoizedValue = React.useMemo(
    () => ({
      contractDetails,
      isLoading,
      isIdle,
      isError,
      setContractDetails,
    }),
    [isLoading, contractDetails, isIdle, isError, setContractDetails]
  );

  return (
    <ContractContext.Provider value={memoizedValue}>
      {children}
    </ContractContext.Provider>
  );
}
