import {
  Box,
  colors,
  ContentText,
  fontWeights,
  IconArrowRight,
  IconHome,
  IconPin,
  px2rem,
} from '@fortum/elemental-ui';
import SummaryBox from '@components/SummaryBox';
import KeyValueRow from '@components/KeyValueRow';
import Divider from '@components/Divider';
import KeyValueColumn from '@components/KeyValueColumn';
import FormattedEnhancedMessage from '@utils/intl-extenders/FormattedEnhancedMessage';
// import EmailChangeBox from '@containers/EmailChangeBox';
import React, { useState } from 'react';
import AddressChangeBox from '@containers/AddressChangeBox';
import Consents from '@components/Consents';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import Spinner from '@components/Spinner';
import { getProperPriceString } from '@utils/prices';
import { GTMButton } from '@components/GTMComponents';
import KeyValuePrice from '@components/KeyValuePrice';
import useContractContext from '@hooks/useContractContext';
import DiscountValidity from '@components/DiscountValidity';
import useIsAdminPage from '@hooks/useIsAdminPage';
import ReadMoreContractModal from '@containers/ReadMoreContractModal';
import useRoutes from '@hooks/useRouting';
import { Address, CustomerType, MonthlyFeeType } from '../../types/api';
import ErrorFallback from '../ErrorFallback';
import { UpdateEmailClickable } from './styled';
import { ISpecialPricesProps, MappedData } from './ContractSummary.types';
import { B2BInfoBox } from './B2BInfoBox';
import { B2CInfoBox } from './B2CInfoBox';

function SpecialPrices({ prices }: ISpecialPricesProps) {
  const { t } = useTranslation();

  if (!prices) return null;
  return (
    <>
      {Object.entries(prices).map(([key, value]) => (
        <KeyValueRow key={key} label={t(`common.${key}`)}>
          {getProperPriceString(value, t)}
        </KeyValueRow>
      ))}
    </>
  );
}

function getName(name?: string | string[]) {
  if (!name) return '';
  if (Array.isArray(name)) return name.join(', ');
  return name;
}

function formatAddress(address: Address) {
  if (!address) return '';
  return `${address.streetName || ''} ${address.houseNumber || ''} ${
    address.houseLetter || ''
  } ${address.residence || ''} ${address.postalCode || ''}, ${
    address.postalCity || ''
  }`;
}

function ContractSummary() {
  const contentfulCategory = 'contractDetails';
  // const [showEmailChange, setShowEmailChange] = React.useState(false);
  const [showAddressChange, setShowAddressChange] = React.useState(false);
  const { t, i18n } = useTranslation();
  const { contractDetails, isLoading, isError } = useContractContext();
  const isAdminContext = useIsAdminPage();
  const [readMoreOpen, setReadMoreOpen] = useState(false);
  const { redirectToNext } = useRoutes();

  if (isError) return <ErrorFallback />;
  if (isLoading || !contractDetails) return <Spinner />;

  const mappedData: MappedData = {
    electricityPrice: contractDetails.electricityPrice,
    isHourlyPriced: contractDetails.electricityPrice?.isHourlyPriced || false,
    monthlyFee: contractDetails.monthlyFee,
    duration: {
      from: dayjs(contractDetails.duration?.from).format('D.MM.YYYY') || '',
      to: contractDetails.duration?.to
        ? dayjs(contractDetails.duration.to).format('D.MM.YYYY')
        : null,
    },
    contractNumber:
      contractDetails.contractCode || contractDetails.contractNumber,
    name: getName(contractDetails.name),
    email: contractDetails.email || '',
    address: contractDetails.address || '',
    meteringPointNo: contractDetails.meteringPointId || '',
    productName: contractDetails.productName || '',
    specialElectricityPrice: contractDetails.specialElectricityPrice || null,
    customerType: contractDetails.customerType,
  };

  const serializedProductName = mappedData.productName
    .toLowerCase()
    .replaceAll(' ', '-');

  // eslint-disable-next-line no-nested-ternary
  const extraPriceText = i18n.exists(`${contentfulCategory}.extraPriceText`)
    ? mappedData.customerType === CustomerType.PRIVATE
      ? t(`${contentfulCategory}.extraPriceText`)
      : t(`${contentfulCategory}.B2B_extraPriceText`)
    : null;

  // function handleEmailChangeClick() {
  //   setShowEmailChange(true);
  // }

  function handleAddressChangeClick() {
    setShowAddressChange(true);
  }

  function onReadMoreModalClose() {
    setReadMoreOpen(false);
  }

  return (
    <>
      {/* <EmailChangeBox show={showEmailChange} setShow={setShowEmailChange} /> */}
      <AddressChangeBox
        show={showAddressChange}
        setShow={setShowAddressChange}
      />
      <ReadMoreContractModal
        productName={serializedProductName}
        productTitle={mappedData.productName}
        open={readMoreOpen}
        onClose={onReadMoreModalClose}
      />
      <SummaryBox
        header={mappedData.productName}
        content={t(`products.${serializedProductName}.description`)}
        containerProps={{
          backgroundColor: colors.oceanGreen,
          marginTop: '1.5rem',
          marginBottom: '1rem',
          alignItems: 'center',
          padding: '2rem',
        }}
        contentProps={{ textAlign: 'center' }}
        icon={t(`products.${serializedProductName}.icon`)}
        onReadMoreClick={() => {
          setReadMoreOpen(true);
        }}
      />
      {mappedData.monthlyFee && (
        <DiscountValidity priceObject={mappedData.monthlyFee} />
      )}
      <Box margin="1rem 0">
        <ContentText size="xl" weight={fontWeights.bold} marginBottom="1rem">
          <FormattedEnhancedMessage i18nKey="common.agreement" />
        </ContentText>
        {mappedData.electricityPrice ? (
          <KeyValuePrice
            label={t(
              mappedData.isHourlyPriced
                ? 'common.hourlyPrice'
                : 'common.electricityPrice'
            )}
            priceObject={mappedData.electricityPrice}
          />
        ) : null}
        {mappedData.monthlyFee ? (
          <KeyValuePrice
            label={t(
              mappedData.monthlyFee?.type === MonthlyFeeType.flat
                ? 'common.flatFee'
                : 'common.monthlyFee'
            )}
            priceObject={mappedData.monthlyFee}
          />
        ) : null}
        <SpecialPrices prices={mappedData.specialElectricityPrice} />
        <KeyValueRow label={t('common.duration')}>
          {mappedData.duration.from}
          {mappedData.duration.to ? (
            <>
              <IconArrowRight
                margin={`0 ${px2rem(12)}`}
                size={16}
                color={colors.oceanGreen}
              />
              {mappedData.duration.to}
            </>
          ) : null}
        </KeyValueRow>
        <KeyValueRow label={t('common.contractNumber')}>
          {mappedData.contractNumber}
        </KeyValueRow>
        {extraPriceText && (
          <ContentText size="s" color={colors.oceanGreen}>
            {extraPriceText}
          </ContentText>
        )}
      </Box>
      <Divider />
      {mappedData.customerType === CustomerType.BUSINESS ? (
        <B2BInfoBox mappedData={mappedData} />
      ) : (
        <B2CInfoBox mappedData={mappedData} />
      )}

      <Divider />
      <Box margin="1rem 0">
        <ContentText size="xl" weight={fontWeights.bold} marginBottom="1rem">
          <FormattedEnhancedMessage i18nKey="common.powerDeliveryAddress" />
        </ContentText>
        <KeyValueColumn
          label={t('common.address')}
          icon={<IconHome color={colors.oceanGreen} marginRight="0.75rem" />}
        >
          {formatAddress(mappedData.address)}
        </KeyValueColumn>
        <KeyValueColumn
          label={t('common.meteringPointNo')}
          icon={<IconPin color={colors.oceanGreen} marginRight="0.75rem" />}
        >
          {mappedData.meteringPointNo}
        </KeyValueColumn>
        <Box marginTop="1rem" display="flex">
          <ContentText size="s" color={colors.emperorGrey}>
            <FormattedEnhancedMessage
              i18nKey={`${contentfulCategory}.wrongDeliveryAddress`}
            />
            &nbsp;
            <UpdateEmailClickable onClick={handleAddressChangeClick}>
              <FormattedEnhancedMessage
                i18nKey={`${contentfulCategory}.wrongDeliveryAddressLink.text`}
              />
            </UpdateEmailClickable>
          </ContentText>
        </Box>
      </Box>
      <Divider />
      <GTMButton
        width="-webkit-fill-available"
        margin="1rem"
        onClick={redirectToNext}
        contentfulKey={`${contentfulCategory}.button`}
        disabled={isAdminContext}
      >
        <FormattedEnhancedMessage i18nKey="common.next" />
      </GTMButton>
      <Consents
        prefix={mappedData.customerType === CustomerType.BUSINESS ? 'B2B_' : ''}
      />
    </>
  );
}

export default ContractSummary;
