import {
  Box,
  ContentText,
  ContentTextProps,
  IconArrowLeft,
} from '@fortum/elemental-ui';
import { useNavigate } from 'react-router-dom';

interface Props {
  header: string;
  headerTextStyle?: ContentTextProps;
  children: React.ReactNode;
}

const SubpageLayout: React.FC<Props> = ({
  header,
  headerTextStyle,
  children,
}) => {
  const navigate = useNavigate();

  function onBackButtonClick() {
    navigate(-1);
  }

  return (
    <Box padding="1rem">
      <Box display="flex" marginBottom="1rem">
        <IconArrowLeft onClick={onBackButtonClick} />
        <ContentText {...headerTextStyle}>{header}</ContentText>
      </Box>
      {children}
    </Box>
  );
};

export default SubpageLayout;
