import {
  Box,
  ContentText,
  Heading,
  colors,
  px2rem,
  fontWeights,
} from '@fortum/elemental-ui';
import styled from 'styled-components';
import AppStoreIcon from '@assets/images/appleStore.svg';
import GooglePlayIcon from '@assets/images/googlePlay.svg';
import PhoneApp from '@assets/images/phoneApp.svg';
import { TickItem } from '@components/IconItems';
import FormattedEnhancedMessage from '@utils/intl-extenders/FormattedEnhancedMessage';
import { useTranslation } from 'react-i18next';
import { GTMA, GTMButton } from '@components/GTMComponents';
import { Helmet } from 'react-helmet-async';

const Container = styled(Box)`
  margin: 0.5rem -1rem;
  background: linear-gradient(136.06deg, #18d270 0.75%, #0f7a5a 76.51%);
  padding: 1rem 2rem 0;
  text-align: center;
`;

const PhoneImage = styled.img`
  margin: 1rem 0 -4px;
`;

function B2CThankYou() {
  const contentfulCategory = 'thankYou';
  const { t } = useTranslation();
  const isFeedbackLoopEnabled = t('settings.B2CFeedbackLoopVisible') === 'true';

  const thankYouItems = t<string, string[]>(`${contentfulCategory}.pointItem`, {
    returnObjects: true,
  });

  async function onLoginButtonClick() {
    window.location.href = t(`${contentfulCategory}.button.login.link`);
  }

  async function onReadMoreButtonClick() {
    window.location.href = t(`${contentfulCategory}.button.readMore.link`);
  }

  return (
    <>
      {isFeedbackLoopEnabled ? (
        <Helmet>
          {/* <!-- Forsta Digital Feedback Program -->  */}
          <script
            id="cf-program-FtVH7p"
            src="https://cdn.horizons.confirmit.eu/loader?programKey=FtVH7p"
            async
            data-rh="true"
          />
          {/* <!-- End Forsta Digital Feedback Program --> */}
        </Helmet>
      ) : null}
      <Container>
        <Heading
          textAlign="center"
          color={colors.snowWhite}
          marginBottom="1rem"
          fontSize={px2rem(29)}
        >
          <FormattedEnhancedMessage i18nKey={`${contentfulCategory}.header`} />
        </Heading>
        {Array.isArray(thankYouItems)
          ? thankYouItems.map((item, index) => (
              <TickItem
                // eslint-disable-next-line react/no-array-index-key
                key={index}
                contentProps={{
                  fontWeight: fontWeights.light,
                  color: colors.snowWhite,
                  textAlign: 'left',
                }}
                iconProps={{
                  color: colors.snowWhite,
                  marginRight: '1rem',
                  size: '26px',
                }}
              >
                {item}
              </TickItem>
            ))
          : null}
        <Box display="flex" justifyContent="space-between">
          <GTMA
            href={t(`${contentfulCategory}.appStoreLink.link`)}
            contentfulKey={`${contentfulCategory}.appStoreLink`}
          >
            <img src={AppStoreIcon} alt="appStore" />
          </GTMA>
          <GTMA
            href={t(`${contentfulCategory}.googlePlayLink.link`)}
            contentfulKey={`${contentfulCategory}.googlePlayLink`}
          >
            <img src={GooglePlayIcon} alt="googlePlay" />
          </GTMA>
        </Box>
        <PhoneImage src={PhoneApp} alt="" />
      </Container>
      <Box textAlign="center" padding="0 1rem">
        {isFeedbackLoopEnabled ? <div id="ForstaContentOB" /> : null}
        <ContentText size="xl" marginBottom="0.5rem">
          <FormattedEnhancedMessage
            i18nKey={`${contentfulCategory}.footer.header`}
          />
        </ContentText>
        <ContentText size="xs" marginBottom="0.5rem">
          <FormattedEnhancedMessage
            i18nKey={`${contentfulCategory}.footer.text`}
          />
        </ContentText>
        <GTMButton
          contentfulKey={`${contentfulCategory}.button.login`}
          width="100%"
          onClick={onLoginButtonClick}
        >
          <FormattedEnhancedMessage
            i18nKey={`${contentfulCategory}.button.login.text`}
          />
        </GTMButton>
        <GTMButton
          contentfulKey={`${contentfulCategory}.button.readMore`}
          width="100%"
          onClick={onReadMoreButtonClick}
          status="secondary"
          marginTop="0.5rem"
        >
          <FormattedEnhancedMessage
            i18nKey={`${contentfulCategory}.button.readMore.text`}
          />
        </GTMButton>
      </Box>
    </>
  );
}

export default B2CThankYou;
