import './App.css';
import Router from '@containers/Router';
import { NotificationProvider } from '@context/Notification';
import { AppProvider } from '@context/App';
import { Suspense } from 'react';
import Spinner from '@components/Spinner';
import { QueryClient, QueryClientProvider } from 'react-query';
import { HelmetProvider } from 'react-helmet-async';
import { AddonsProvider } from '@context/Addons';
import { AdminProvider } from '@context/Admin';

const queryClient = new QueryClient();

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <HelmetProvider>
        <AdminProvider>
          <AppProvider>
            <NotificationProvider>
              <AddonsProvider>
                <Suspense fallback={<Spinner />}>
                  <Router />
                </Suspense>
              </AddonsProvider>
            </NotificationProvider>
          </AppProvider>
        </AdminProvider>
      </HelmetProvider>
    </QueryClientProvider>
  );
}

export default App;
