import { TFunction } from 'react-i18next';
import { PriceKeys, PriceObject, PricesList } from 'types/api';

function formatOutputPrice(
  returnDiscount: boolean,
  validPrice: PriceObject,
  t: TFunction<'translation', undefined>
) {
  const baseValue =
    returnDiscount && validPrice?.discount
      ? validPrice.price + validPrice.discount
      : validPrice?.price;
  if (validPrice?.unit === '€/mnth')
    return t('common.eurPerMonth', {
      value: baseValue.toFixed(2).replaceAll('.', ','),
    });
  return `${baseValue?.toFixed(2).replaceAll('.', ',') || ''} ${
    validPrice?.unit || ''
  }`;
}

export function getLastPriceObject(pricesList: PricesList) {
  const priceKeys = Object.keys(pricesList);
  const priceKeysLen = priceKeys.length;
  return pricesList[priceKeys[priceKeysLen - 1] as PriceKeys] as PriceObject;
}

export function getProperPriceString(
  priceObject: PriceObject | PricesList,
  t: TFunction<'translation', undefined>,
  returnDiscount = false
) {
  if (!priceObject) return null;

  if ('fee' in priceObject && priceObject.fee) {
    if ('energy' in priceObject && priceObject.energy) {
      return `${formatOutputPrice(
        returnDiscount,
        priceObject.fee,
        t
      )} + ${formatOutputPrice(returnDiscount, priceObject.energy, t)}`;
    }
    return formatOutputPrice(returnDiscount, priceObject.fee, t);
  }

  if ('price' in priceObject) {
    return formatOutputPrice(returnDiscount, priceObject, t);
  }

  const pricesList = priceObject as PricesList;

  return formatOutputPrice(returnDiscount, getLastPriceObject(pricesList), t);
}

export function getAddonBasePriceValue(priceObject: PriceObject) {
  if (Array.isArray(priceObject)) return priceObject?.[1] || priceObject?.[0];
  return priceObject;
}

export function hasDiscount(priceObject: PriceObject | PricesList) {
  if ('discount' in priceObject && priceObject?.discount)
    return !!priceObject?.discount;
  return !!getLastPriceObject(priceObject as PricesList)?.discount;
}
