import {
  Box,
  Heading,
  colors,
  px2rem,
  fontWeights,
  ContentText,
} from '@fortum/elemental-ui';
import styled from 'styled-components';
import ThankYouBackground from '@assets/images/thankYouBackground.png';
import { TickItem } from '@components/IconItems';
import FormattedEnhancedMessage from '@utils/intl-extenders/FormattedEnhancedMessage';
import { useTranslation } from 'react-i18next';
import { GTMButton } from '@components/GTMComponents';
import { ContractDetails } from 'types/api';
import { Helmet } from 'react-helmet-async';

const Container = styled(Box)`
  text-align: center;
  background: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0.35) 0%,
      rgba(0, 0, 0, 0.35) 100%
    ),
    url(${ThankYouBackground}), lightgray 50% / cover no-repeat;

  background-size: 100%;
  background-repeat: no-repeat;
  padding: 2rem 1rem;
  min-height: 450px;
  max-height: 545px;
`;

function B2BThankYou({
  contractDetails,
}: {
  contractDetails: ContractDetails;
}) {
  const contentfulCategory = 'thankYouB2B';
  const { t } = useTranslation();
  const isFeedbackLoopEnabled = t('settings.B2BFeedbackLoopVisible') === 'true';

  const thankYouItems = t<string, string[]>(`${contentfulCategory}.pointItem`, {
    returnObjects: true,
  });

  async function onLoginButtonClick() {
    window.location.href = t(`${contentfulCategory}.button.login.link`);
  }

  async function onReadMoreButtonClick() {
    window.location.href = t(`${contentfulCategory}.button.readMore.link`);
  }

  const isProd = process.env.REACT_APP_ENVIRONMENT === 'prod';

  return (
    <>
      {isFeedbackLoopEnabled ? (
        <Helmet>
          {/* <!-- Forsta Digital Feedback Program -->  */}
          <script
            id={isProd ? 'cf-program-e1tKhT' : 'cf-program-FtVH7p'}
            src={`https://cdn.horizons.confirmit.eu/loader?programKey=${
              isProd ? 'e1tKhT' : 'FtVH7p'
            }`}
            async
            data-rh="true"
          />
          {/* <!-- End Forsta Digital Feedback Program --> */}
        </Helmet>
      ) : null}
      <Box
        margin="0.5rem -1rem"
        height="100%"
        display="flex"
        flexDirection="column"
        justifyContent="space-around"
        alignItems="center"
      >
        <Container>
          <Heading
            textAlign="center"
            color={colors.snowWhite}
            marginBottom="1rem"
            fontSize={px2rem(29)}
          >
            <FormattedEnhancedMessage
              i18nKey={`${contentfulCategory}.header`}
            />
          </Heading>
          <Box
            display="flex"
            alignContent="center"
            flexDirection="column"
            paddingLeft="2rem"
          >
            {Array.isArray(thankYouItems)
              ? thankYouItems.map((item, index) => (
                  <TickItem
                    // eslint-disable-next-line react/no-array-index-key
                    key={index}
                    contentProps={{
                      fontWeight: fontWeights.light,
                      color: colors.snowWhite,
                      textAlign: 'left',
                      lineHeight: 2,
                    }}
                    iconProps={{
                      color: colors.snowWhite,
                      marginRight: '1rem',
                      size: '26px',
                    }}
                  >
                    {item}
                  </TickItem>
                ))
              : null}
          </Box>
          {isFeedbackLoopEnabled ? <div id="ForstaContentOB" /> : null}
        </Container>
        <ContentText size="xl" marginBottom="0.5rem">
          <FormattedEnhancedMessage
            i18nKey={`${contentfulCategory}.footer.header`}
          />
        </ContentText>
        <ContentText size="xs" padding="0 1rem 0.5rem">
          <FormattedEnhancedMessage
            i18nKey={`${contentfulCategory}.footer.text`}
          />
        </ContentText>
        <ContentText size="xs" marginBottom="0.5rem">
          <FormattedEnhancedMessage
            i18nKey={`${contentfulCategory}.footer.customerId`}
            values={{ id: contractDetails.customerId }}
          />
        </ContentText>
        <GTMButton
          contentfulKey={`${contentfulCategory}.button.login`}
          width="18.5rem"
          onClick={onLoginButtonClick}
          mt="2.5rem"
        >
          <FormattedEnhancedMessage
            i18nKey={`${contentfulCategory}.button.login.text`}
          />
        </GTMButton>
        <GTMButton
          contentfulKey={`${contentfulCategory}.button.readMore`}
          onClick={onReadMoreButtonClick}
          status="secondary"
          marginTop="0.5rem"
          width="18.5rem"
        >
          <FormattedEnhancedMessage
            i18nKey={`${contentfulCategory}.button.readMore.text`}
          />
        </GTMButton>
      </Box>
    </>
  );
}

export default B2BThankYou;
