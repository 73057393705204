import {
  ContentText,
  LazyIcon,
  Notification,
  colors,
} from '@fortum/elemental-ui';
import useNotificationContext from '@hooks/useNotificationContext';
import { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';

const NotificationToast = () => {
  const { notification, setNotification } = useNotificationContext();
  const [viewportHeight, setViewportHeight] = useState(
    visualViewport?.height || 0
  );

  function onNotificationClose() {
    setNotification((prev) => ({ ...prev, visible: false }));
  }

  useEffect(() => {
    function onResize(event: VisualViewportEventMap['resize']) {
      const height = (event?.target as VisualViewport)?.height;
      setViewportHeight(height);
    }

    visualViewport?.addEventListener('resize', onResize);

    return () => visualViewport?.removeEventListener('resize', onResize);
  }, []);

  return ReactDOM.createPortal(
    <Notification
      position="fixed"
      top={
        notification.visible
          ? `calc(${window.innerHeight - viewportHeight}px + 20px)`
          : `-50rem`
      }
      transition="top 1s"
      right="1rem"
      left="1rem"
      zIndex={1002}
      icon={<LazyIcon iconId={notification.icon || 'IconError'} />}
      onClose={onNotificationClose}
      opened={notification.visible}
      backgroundColor={notification.color || colors.forestGreen}
    >
      <ContentText display="flex" alignItems="center">
        {notification.text}
      </ContentText>
    </Notification>,
    document.body
  );
};

export default NotificationToast;
