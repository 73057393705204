import BottomModal from '@containers/BottomModal';
import {
  Box,
  colors,
  ContentText,
  fontWeights,
  lineHeights,
} from '@fortum/elemental-ui';
import FormattedEnhancedMessage from '@utils/intl-extenders/FormattedEnhancedMessage';
import { useTranslation } from 'react-i18next';

interface Props {
  open: boolean;
  onClose: () => void;
}

function ReadMoreInvoiceModal({ open, onClose }: Props) {
  const contentfulCategory = 'invoice';
  const { t, i18n } = useTranslation();
  const imgTranslationKey = `${contentfulCategory}.readMore.image`;

  return (
    <BottomModal show={open} setShow={onClose} showCloseButton>
      {i18n.exists(imgTranslationKey) ? (
        <Box margin="0 -1rem 0.5rem">
          <img src={t(imgTranslationKey)} alt="e-invoice" width="100%" />
        </Box>
      ) : null}
      <Box margin="1rem 0">
        <Box
          display="flex"
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between"
          marginBottom="1.5rem"
        >
          <ContentText size="xl" lineHeight={lineHeights.tight}>
            {t(`${contentfulCategory}.readMore.title`)}
          </ContentText>
        </Box>
        <ContentText
          color={colors.inkGrey}
          weight={fontWeights.light}
          textAlign="justify"
          lineHeight={lineHeights.loose}
        >
          <FormattedEnhancedMessage
            i18nKey={`${contentfulCategory}.readMore.description`}
          />
        </ContentText>
      </Box>
    </BottomModal>
  );
}

export default ReadMoreInvoiceModal;
