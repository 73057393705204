import Stepper from '@components/Stepper';
import { Box, colors } from '@fortum/elemental-ui';
import { mainFlowRoutes } from '@constants/routes';
import { Outlet } from 'react-router-dom';
import useRoutes from '@hooks/useRouting';

const MainFlowLayout: React.FC = () => {
  const { currentRoute } = useRoutes();
  return (
    <Box
      padding="1rem"
      backgroundColor={
        currentRoute?.includes('thank-you') ? colors.lightGrey : 'transparent'
      }
    >
      <Stepper steps={mainFlowRoutes} />
      <Outlet />
    </Box>
  );
};

export default MainFlowLayout;
