import { useTranslation } from 'react-i18next';
import KeyValueColumn from '@components/KeyValueColumn';
import FormattedEnhancedMessage from '@utils/intl-extenders/FormattedEnhancedMessage';
import {
  Box,
  ContentText,
  IconBuilding,
  IconEmail,
  colors,
  fontWeights,
} from '@fortum/elemental-ui';
import { MappedData } from './ContractSummary.types';

export function B2BInfoBox({ mappedData }: { mappedData: MappedData }) {
  const { t } = useTranslation();
  const { name, email } = mappedData;
  return (
    <Box margin="1rem 0">
      <ContentText size="xl" weight={fontWeights.bold} marginBottom="1rem">
        <FormattedEnhancedMessage i18nKey="common.companyInformation" />
      </ContentText>
      <KeyValueColumn
        label={t('common.companyName')}
        icon={<IconBuilding color={colors.oceanGreen} marginRight="0.75rem" />}
      >
        {name}
      </KeyValueColumn>

      <KeyValueColumn
        label={t('common.mainContactPersonEmail')}
        icon={<IconEmail color={colors.oceanGreen} marginRight="0.75rem" />}
      >
        {email}
      </KeyValueColumn>
    </Box>
  );
}
