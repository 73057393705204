import {
  colors,
  ContentText,
  Box,
  BoxProps,
  IconChevronDown,
  fontWeights,
} from '@fortum/elemental-ui';
import React from 'react';
import styled from 'styled-components';
import { CSSTransition } from 'react-transition-group';
import FormattedEnhancedChildren from '@utils/intl-extenders/FormattedEnhancedChildren';

interface ContentContainerProps {
  open?: boolean;
}

const ContentContainer = styled.div<ContentContainerProps>`
  transition: max-height 0.5s, opacity 0.2s, visibility 0.5s, margin-top 0.5s;

  &.contentContainer-enter {
    visibility: hidden;
    opacity: 0;
    max-height: 0;
    margin-top: 0;
  }

  &.contentContainer-enter-active,
  &.contentContainer-enter-done {
    visibility: visible;
    opacity: 1;
    max-height: 1000px;
    margin-top: 2rem;
  }
  &.contentContainer-exit-active {
    visibility: hidden;
    opacity: 0;
    max-height: 0;
    margin-top: 0;
  }
`;

interface Props {
  children: React.ReactNode;
  containerProps?: BoxProps;
  image: string;
  text: string;
}

const ClickableQuestionItem: React.FC<Props> = ({
  containerProps,
  image,
  text,
  children,
}) => {
  const [open, setOpen] = React.useState(false);
  const contentRef = React.useRef(null);

  function onClick() {
    setOpen((prev) => !prev);
  }

  return (
    <Box
      display="flex"
      width="100%"
      padding="1rem"
      backgroundColor={colors.lightGrey}
      flexDirection="column"
      {...containerProps}
    >
      <Box
        display="flex"
        width="100%"
        justifyContent="space-between"
        onClick={onClick}
      >
        <ContentText color={colors.inkGrey}>{children}</ContentText>
        <IconChevronDown
          color={colors.inkGrey}
          size="1.5rem"
          transition="transform 0.5s"
          transform={open ? 'rotate(180deg)' : 'rotate(0)'}
        />
      </Box>
      <CSSTransition
        in={open}
        unmountOnExit
        classNames="contentContainer"
        nodeRef={contentRef}
        timeout={{ enter: 200, exit: 500 }}
      >
        <ContentContainer ref={contentRef}>
          <img src={image} alt="question-img" width="100%" />
          <ContentText
            color={colors.inkGrey}
            weight={fontWeights.light}
            marginTop="1rem"
          >
            <FormattedEnhancedChildren>{text}</FormattedEnhancedChildren>
          </ContentText>
        </ContentContainer>
      </CSSTransition>
    </Box>
  );
};

export default ClickableQuestionItem;
