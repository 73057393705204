import BottomModal from '@containers/BottomModal';
import {
  Heading,
  ContentText,
  fontWeights,
  colors,
  Button,
} from '@fortum/elemental-ui';
import useAppContext from '@hooks/useAppContext';
import FormattedEnhancedMessage from '@utils/intl-extenders/FormattedEnhancedMessage';
import React from 'react';
import useQuery from '@hooks/useQuery';
import { useTranslation } from 'react-i18next';
import useApi from '@hooks/useApi';
import useNotificationContext from '@hooks/useNotificationContext';
import { AxiosError } from 'axios';

const InitialPinCodeBox: React.FC<{ onSubmit: () => void; email: string }> = ({
  onSubmit,
  email,
}) => {
  const [resendLoading, setResendLoading] = React.useState(false);
  const { t } = useTranslation();
  const contentfulCategory = 'landing.initialPincode';
  const { authToken } = useAppContext();
  const query: URLSearchParams = useQuery();
  const code = query.get('code');
  const { requestPincode } = useApi();
  const { setNotification } = useNotificationContext();

  const onSendClick = React.useCallback(async () => {
    if (!code) return;
    try {
      setResendLoading(true);
      await requestPincode(code);
      setResendLoading(false);
      setNotification({
        text: t(`${contentfulCategory}.codeSentNotification`),
        visible: true,
        icon: 'IconCheck',
      });
      onSubmit();
    } catch (error) {
      if (
        error instanceof AxiosError &&
        error.response?.data?.message === 'Link expired'
      ) {
        setNotification({
          text: t('errors.pincodeExpired'),
          visible: true,
          color: colors.punchRed,
        });
      } else {
        setNotification({
          text: t('errors.initialPincode'),
          visible: true,
          color: colors.punchRed,
        });
      }
      setResendLoading(false);
    }
  }, [code, setNotification, t, onSubmit, requestPincode]);

  return (
    <BottomModal
      show={!authToken}
      contentProps={{
        padding: '2rem 2rem 3.5rem',
      }}
    >
      <Heading
        weight={fontWeights.bold}
        fontSize="1.25rem"
        size={5}
        marginBottom="0.5rem"
      >
        {t(`${contentfulCategory}.heading`)}
      </Heading>
      <ContentText>
        <FormattedEnhancedMessage
          i18nKey={`${contentfulCategory}.content`}
          values={{ email }}
        />
      </ContentText>
      <Button
        marginTop="1rem"
        onClick={onSendClick}
        disabled={resendLoading}
        width="100%"
      >
        {t(`${contentfulCategory}.button`)}
      </Button>
      {/* <ContentText marginTop="1.5rem">
        <FormattedEnhancedMessage
          i18nKey={`${contentfulCategory}.resend.text`}
        />
      </ContentText> */}
    </BottomModal>
  );
};

export default InitialPinCodeBox;
