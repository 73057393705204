import { colors } from '@fortum/elemental-ui';
import styled from 'styled-components';

type StepProps = {
  done?: boolean;
};

export const Step = styled.div<StepProps>`
  border-radius: 2px;
  background-color: ${({ done }) =>
    done ? colors.poweringGreen : colors.fogGrey};
  flex-grow: 1;
  height: 3px;
`;
