import { Box } from '@fortum/elemental-ui';
import React from 'react';
import PinBox from './PinBox';
import { Input } from './styled';

interface Props {
  value: string;
  setValue: React.Dispatch<React.SetStateAction<string>>;
  digits: number;
}

const PinInput: React.FC<Props> = ({ value, setValue, digits }) => {
  const inputRef = React.useRef<HTMLInputElement | null>(null);

  function onContainerClick() {
    inputRef.current?.focus();
  }

  function onChange(e: React.ChangeEvent<HTMLInputElement>) {
    const newValue = e.target.value;
    setValue(newValue);
  }

  return (
    <Box
      display="flex"
      justifyContent="space-between"
      marginTop="1.5rem"
      onClick={onContainerClick}
    >
      <Input
        onChange={onChange}
        ref={inputRef}
        type="number"
        value={value}
        maxLength={digits}
      />
      {Array.from({ length: digits }).map((_, index) => (
        <PinBox
          // eslint-disable-next-line react/no-array-index-key
          key={index}
          hasValue={!!value[index]}
        />
      ))}
    </Box>
  );
};

export default PinInput;
