import { Box, colors, ContentText } from '@fortum/elemental-ui';

interface Props {
  label: string;
  children: React.ReactNode;
}

const KeyValueRow: React.FC<Props> = ({ label, children }) => {
  return (
    <Box
      display="flex"
      justifyContent="space-between"
      width="100%"
      alignItems="center"
      margin="0"
      marginBottom="0.5rem"
    >
      <ContentText color={colors.emperorGrey} size="s">
        {label}
      </ContentText>
      <ContentText color={colors.inkGrey} display="flex">
        {children}
      </ContentText>
    </Box>
  );
};

export default KeyValueRow;
