import {
  Box,
  colors,
  ContentText,
  IconCostEfficient,
} from '@fortum/elemental-ui';
import { getProperPriceString, hasDiscount } from '@utils/prices';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { PriceObject } from 'types/api';

interface Props {
  priceObject: PriceObject;
}

const DiscountValidity = ({ priceObject }: Props) => {
  const { t } = useTranslation();
  const discount = getProperPriceString(priceObject, t, true);
  const from = priceObject
    ? dayjs(priceObject.discountFrom).format('D.MM.YYYY')
    : 'None';
  const to = priceObject
    ? dayjs(priceObject.discountTo).format('D.MM.YYYY')
    : 'None';
  const hasDiscountValue = hasDiscount(priceObject);
  const { vat } = priceObject;

  if (!hasDiscountValue) return null;

  return (
    <Box
      display="flex"
      border={`1px solid ${colors.oceanGreen}`}
      boxShadow="0px 0px 27px 8px rgba(0, 0, 0, 0.1)"
      padding="0.5rem"
      alignItems="center"
      justifyContent="center"
    >
      <IconCostEfficient
        width="24px"
        height="24px"
        color={colors.oceanGreen}
        marginRight="1rem"
      />
      <ContentText color={colors.oceanGreen}>
        {t('contractDetails.discountBox', { from, to, discount, vat })}
      </ContentText>
    </Box>
  );
};

export default DiscountValidity;
