import styled from 'styled-components';

export const BaseGradient = styled.div`
  background: radial-gradient(
    37.5% 32.71% at 56.19% 78.41%,
    #4da577 0%,
    #0f7a5a 100%
  );
  position: absolute;
  width: 800px;
  height: 800px;
  left: -266px;
  top: -284px;
  z-index: -1;
  border-radius: 50%;
`;

export const BackgroundGradient = styled.div`
  position: absolute;
  width: 800px;
  height: 800px;
  left: -167px;
  top: -272px;
  border-radius: 50%;
  z-index: -2;
  background: radial-gradient(
    58.58% 19.34% at 50% 76.09%,
    #4da577 0%,
    #0f7a5a 100%
  );
`;
