import { RenderedRoute } from '@constants/routes';
import ProtectedRoute from '@containers/ProtectedRoute';
import { Route } from 'react-router-dom';

interface Props {
  routes: RenderedRoute[];
}

const renderRoutes = ({ routes }: Props) => {
  return (
    <>
      {routes.map((route) => {
        const Component = route.component;
        if (route.unprotected)
          return (
            <Route
              key={route.path}
              path={route.path}
              element={
                <ProtectedRoute>
                  <Component />
                </ProtectedRoute>
              }
            />
          );
        return (
          <Route key={route.path} path={route.path} element={<Component />} />
        );
      })}
    </>
  );
};

export default renderRoutes;
