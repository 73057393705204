import {
  Heading,
  FortumLogo,
  Box,
  colors,
  ContentText,
  fontWeights,
} from '@fortum/elemental-ui';
import PinCodeBox from '@containers/PinCodeBox';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { GTMButton } from '@components/GTMComponents';
import { useCallback, useEffect, useState } from 'react';
import { validateUrl } from '@utils/API';
import useQuery from '@hooks/useQuery';
import Spinner from '@components/Spinner';
import InitialPinCodeBox from '@containers/InitialPinCodeBox';
import LanguageSwitcher from '@components/LanguageSwitcher';
import { BasicIconItem } from '@components/IconItems';
import useIsAdminPage from '@hooks/useIsAdminPage';
import { BackgroundGradient, BaseGradient } from './styled';
import ErrorFallback from '../ErrorFallback';

function LandingPage() {
  const { t } = useTranslation();
  const contentfulCategory = 'landing';
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [isUrlValid, setIsUrlValid] = useState(true);
  const [initialSendDone, setInitialSendDone] = useState(false);
  const query: URLSearchParams = useQuery();
  const code = query.get('code');
  const [userEmail, setUserEmail] = useState('');
  const isAdminPage = useIsAdminPage();

  function onClick() {
    navigate('/contract-summary');
  }

  const featureItems = t<string, { text: string; icon: string }[]>(
    `${contentfulCategory}.featureItem`,
    {
      returnObjects: true,
    }
  );

  const doUrlValidation = useCallback(async () => {
    try {
      setLoading(true);
      if (!code) setIsUrlValid(false);
      else {
        const resp = await validateUrl({ url: code });
        if (resp.status !== 200) setIsUrlValid(false);
        else setUserEmail(resp.data.email);
      }
    } catch (error) {
      setIsUrlValid(false);
    } finally {
      setLoading(false);
    }
  }, [code]);

  useEffect(() => {
    if (isAdminPage) return;
    doUrlValidation();
  }, [doUrlValidation, isAdminPage]);

  if (loading && !isAdminPage) return <Spinner />;

  if (!isUrlValid) return <ErrorFallback error="errors.wrongPincode.content" />;

  return (
    <Box
      position="relative"
      height="100%"
      overflow="hidden"
      padding="2rem 2rem 1rem"
    >
      <BaseGradient />
      <BackgroundGradient />
      <Box position="absolute" top="1rem" right="1rem" zIndex={1001}>
        <LanguageSwitcher disableLoading color={colors.snowWhite} />
      </Box>
      <FortumLogo
        marginBottom="2rem"
        textAlign="center"
        width="100%"
        color={colors.snowWhite}
        justifyContent="center"
      />
      <Heading
        color={colors.snowWhite}
        textAlign="left"
        marginBottom="1rem"
        weight={fontWeights.bold}
        lineHeight={1.2}
      >
        {t(`${contentfulCategory}.header`)}
      </Heading>
      <ContentText
        color={colors.snowWhite}
        weight={fontWeights.light}
        marginBottom="2rem"
      >
        {t(`${contentfulCategory}.text`)}
      </ContentText>
      {Array.isArray(featureItems)
        ? featureItems.map((item) => (
            <BasicIconItem key={item.icon + item.text} icon={item.icon}>
              {item.text}
            </BasicIconItem>
          ))
        : null}
      <GTMButton
        position="absolute"
        bottom="1rem"
        left="1rem"
        right="1rem"
        onClick={onClick}
        contentfulKey={`${contentfulCategory}.button`}
        disabled={isAdminPage}
      >
        {t(`${contentfulCategory}.buttonText`)}
      </GTMButton>
      {/* eslint-disable-next-line no-nested-ternary */}
      {isAdminPage ? null : initialSendDone ? (
        <PinCodeBox />
      ) : (
        <InitialPinCodeBox
          onSubmit={() => setInitialSendDone(true)}
          email={userEmail}
        />
      )}
    </Box>
  );
}

export default LandingPage;
