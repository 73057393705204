import KeyValueRow from '@components/KeyValueRow';
import { Box, ContentText, DatePicker } from '@fortum/elemental-ui';
import useAdminContext from '@hooks/useAdminContext';
import { adminGetStats } from '@utils/API';
import { AxiosError } from 'axios';
import dayjs from 'dayjs';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AdminGetStatsResponse } from 'types/api';

function AdminStats() {
  const [data, setData] = useState<AdminGetStatsResponse | undefined>();
  const [selectedDate, setSelectedDate] = useState<Date | null>(null);
  const { authToken, logout, tokenSet } = useAdminContext();
  const navigate = useNavigate();

  const getData = useCallback(async () => {
    try {
      const resp = await adminGetStats({
        authToken,
      });
      setData(resp);
    } catch (error) {
      if (
        error instanceof AxiosError &&
        (error.response?.status === 403 || error.response?.status === 401)
      ) {
        logout();
        navigate('/admin/login');
      }
    }
  }, [authToken, logout, navigate]);

  useEffect(() => {
    if (!tokenSet) return;
    getData();
  }, [getData, tokenSet, authToken]);

  const includedDates = data
    ? [
        ...data.customers.map((customer) => new Date(customer.date)),
        ...data.magicLinks.map((link) => new Date(link.date)),
      ]
    : [];

  return (
    <Box width="100%" mb="1rem">
      <ContentText size="xl" marginBottom="2rem" textAlign="left">
        Statistics
      </ContentText>
      <Box width="fit-content">
        <DatePicker
          locale="en"
          name="date"
          label="Date"
          onChange={setSelectedDate}
          selected={selectedDate || undefined}
          includeDates={includedDates}
          style={{ marginBottom: '1rem' }}
        />

        <KeyValueRow label="Customers">
          {
            data?.customers.find((customer) =>
              dayjs(selectedDate).isSame(customer.date, 'date')
            )?.amount
          }
        </KeyValueRow>

        <KeyValueRow label="SMSes">
          {
            data?.magicLinks.find((magicLink) =>
              dayjs(selectedDate).isSame(magicLink.date, 'date')
            )?.amount
          }
        </KeyValueRow>
      </Box>
    </Box>
  );
}

export default AdminStats;
