import {
  colors,
  ContentText,
  Box,
  IconChevronRight,
  BoxProps,
} from '@fortum/elemental-ui';

interface Props {
  children: React.ReactNode;
  onClick?: () => void;
  containerProps?: BoxProps;
}

const BasicQuestionItem: React.FC<Props> = ({
  containerProps,
  onClick,
  children,
}) => {
  return (
    <Box
      display="flex"
      width="100%"
      padding="0.5rem 1rem"
      backgroundColor={colors.lightGrey}
      justifyContent="space-between"
      onClick={onClick}
      {...containerProps}
    >
      <ContentText color={colors.inkGrey}>{children}</ContentText>
      <IconChevronRight color={colors.inkGrey} size="1.5rem" />
    </Box>
  );
};

export default BasicQuestionItem;
