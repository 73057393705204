import { GTMButton } from '@components/GTMComponents';
import BottomModal from '@containers/BottomModal';
import { ContentText, fontWeights, colors, Select } from '@fortum/elemental-ui';
import useIsAdminPage from '@hooks/useIsAdminPage';
import FormattedEnhancedMessage from '@utils/intl-extenders/FormattedEnhancedMessage';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
  show: boolean;
  setShow: React.Dispatch<React.SetStateAction<boolean>>;
  onActivate: (selectedBank: string) => void;
  loading: boolean;
}

const BankActivationModal: React.FC<Props> = ({
  show,
  setShow,
  onActivate,
  loading,
}) => {
  const contentfulCategory = 'invoice.bankActivation';
  const { t } = useTranslation();
  const [selectedBank, setSelectedBank] = useState<string | null>(null);
  const isAdminPage = useIsAdminPage();

  const banks = t<string, string>(`banks`, {
    returnObjects: true,
  });

  const mappedBanks = Object.entries(banks).map(([value, name]) => ({
    name,
    value,
  }));

  return (
    <BottomModal
      show={show}
      setShow={setShow}
      showCloseButton
      contentProps={{ paddingBottom: '3rem' }}
    >
      <ContentText size="xl" weight={fontWeights.bold} marginBottom="0.5rem">
        <FormattedEnhancedMessage i18nKey={`${contentfulCategory}.header`} />
      </ContentText>
      <ContentText marginBottom="1.5rem">
        <FormattedEnhancedMessage i18nKey={`${contentfulCategory}.content`} />
      </ContentText>
      <Select
        name="bank"
        items={mappedBanks}
        label={t(`${contentfulCategory}.selectLabel`)}
        placeholder={t(`${contentfulCategory}.selectText`)}
        showItems={2}
        onChange={(e) => setSelectedBank(e.target.value)}
      />
      <GTMButton
        width="100%"
        marginTop="2rem"
        backgroundColor={colors.cushyBlue}
        onClick={() => selectedBank && onActivate(selectedBank)}
        contentfulKey={`${contentfulCategory}.button`}
        disabled={!selectedBank || loading || isAdminPage}
      >
        <FormattedEnhancedMessage
          i18nKey={`${contentfulCategory}.button.content`}
        />
      </GTMButton>
    </BottomModal>
  );
};

export default BankActivationModal;
