import React, { useCallback } from 'react';
import { Addon } from 'types/api';

interface AddonsContextType {
  toActivate: Addon[];
  toTerminate: Addon[];
  recommended: Addon | null;
  handleToActivate(addon: Addon): void;
  handleToTerminate(addon: Addon): void;
  handleRecommended(addon: Addon): void;
  recommendedActivated: boolean;
  setRecommendedActivated: React.Dispatch<React.SetStateAction<boolean>>;
}

export const AddonsContext = React.createContext<AddonsContextType>(
  {} as AddonsContextType
);

export function AddonsProvider({
  children,
}: {
  children: React.ReactNode;
}): JSX.Element {
  const [toActivate, setToActivate] = React.useState<Addon[]>([]);
  const [toTerminate, setToTerminate] = React.useState<Addon[]>([]);
  const [recommended, setRecommended] = React.useState<Addon | null>(null);
  const [recommendedActivated, setRecommendedActivated] =
    React.useState<boolean>(false);

  const addToActivate = useCallback((addon: Addon) => {
    setToActivate((prev) => [...prev, addon]);
  }, []);

  const removeToActivate = useCallback((addon: Addon) => {
    setToActivate((prev) => prev.filter((a) => a.tariffNo !== addon.tariffNo));
  }, []);

  const handleToActivate = useCallback(
    (addon: Addon) => {
      if (toActivate.find((item) => item.tariffId === addon.tariffId)) {
        removeToActivate(addon);
      } else {
        addToActivate(addon);
      }
    },
    [addToActivate, removeToActivate, toActivate]
  );

  const handleRecommended = useCallback(
    (addon: Addon) => {
      if (recommended) {
        setRecommended(null);
      } else {
        setRecommended(addon);
      }
    },
    [recommended]
  );

  const addToTerminate = useCallback((addon: Addon) => {
    setToTerminate((prev) => [...prev, addon]);
  }, []);

  const removeToTerminate = useCallback((addon: Addon) => {
    setToTerminate((prev) => prev.filter((a) => a.tariffNo !== addon.tariffNo));
  }, []);

  const handleToTerminate = useCallback(
    (addon: Addon) => {
      if (toTerminate.find((item) => item.tariffId === addon.tariffId)) {
        removeToTerminate(addon);
      } else {
        addToTerminate(addon);
      }
    },
    [addToTerminate, removeToTerminate, toTerminate]
  );

  const memoizedValue = React.useMemo(
    () => ({
      toActivate,
      handleToActivate,
      toTerminate,
      handleToTerminate,
      recommended,
      handleRecommended,
      recommendedActivated,
      setRecommendedActivated,
    }),
    [
      toActivate,
      handleToActivate,
      toTerminate,
      handleToTerminate,
      recommended,
      handleRecommended,
      recommendedActivated,
      setRecommendedActivated,
    ]
  );

  return (
    <AddonsContext.Provider value={memoizedValue}>
      {children}
    </AddonsContext.Provider>
  );
}
