import { FunctionComponent, ReactElement } from 'react';
import { Trans } from 'react-i18next';
import getEnhancedMessageValues from './enhanced-values';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const FormattedEnhancedChildren: FunctionComponent<any> = ({
  children,
  ...props
}): ReactElement<typeof Trans> => {
  return (
    <Trans
      {...props}
      default=""
      components={{
        ...getEnhancedMessageValues(),
        ...props.components,
      }}
    >
      {children}
    </Trans>
  );
};

export default FormattedEnhancedChildren;
