import { Addon, ContractDetails } from 'types/api';

function mapAddons(addon: Addon): Addon {
  return { ...addon, tariffId: addon.tariffId.trim() };
}

export function mapContractDetails(data: ContractDetails): ContractDetails {
  const activeAddons = data.activeAddons?.map(mapAddons) || [];
  const inactiveAddons = data.inactiveAddons?.map(mapAddons) || [];
  const recommendedAddon = data.recommendedAddon
    ? mapAddons(data.recommendedAddon)
    : undefined;
  return { ...data, activeAddons, inactiveAddons, recommendedAddon };
}
