import { authorizePin, getStepsOrder, verifyToken } from '@utils/API';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Addon } from 'types/api';

interface AppContextType {
  authToken: string;
  setAuthToken: React.Dispatch<React.SetStateAction<string>>;
  authorize: (urlCode: string, pin: string) => Promise<void>;
  verify: () => Promise<void>;
  loading: boolean;
  activeAddonDetails: Addon | null;
  setActiveAddonDetails: React.Dispatch<React.SetStateAction<Addon | null>>;
  fetchedRoutes: { id: string; order: number }[];
  vasPromotionDisable: boolean;
}

export const AppContext = React.createContext<AppContextType>(
  {} as AppContextType
);

export function AppProvider({
  children,
}: {
  children: React.ReactNode;
}): JSX.Element {
  const [authToken, setAuthToken] = React.useState<string>('');
  const [loading, setLoading] = React.useState<boolean>(true);
  const [activeAddonDetails, setActiveAddonDetails] =
    React.useState<Addon | null>(null);
  const [fetchedRoutes, setFetchedRoutes] = React.useState([]);
  const { t } = useTranslation();
  const vasPromotionDisable = t('config.disableVasPromotion') === 'true';

  const fetchRoutes = useCallback(async () => {
    try {
      if (fetchedRoutes?.length) return;
      setLoading(true);
      const data = await getStepsOrder();
      setFetchedRoutes(data.steps);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  }, [fetchedRoutes]);

  React.useEffect(() => {
    fetchRoutes();
  }, [fetchRoutes]);

  React.useEffect(() => {
    const foundToken = localStorage.getItem('token') as string;
    if (foundToken) setAuthToken(foundToken);
  }, []);

  const authorize = React.useCallback(async (urlCode: string, pin: string) => {
    try {
      const result = await authorizePin(urlCode, pin);
      if (result) {
        setAuthToken(result);
        localStorage.setItem('token', result);
      }
    } catch (error) {
      setAuthToken('');
    }
  }, []);

  const verify = React.useCallback(async () => {
    if (authToken) return setLoading(false);
    try {
      setLoading(true);
      // const token = localStorage.getItem('token') as string;
      const result = await verifyToken(authToken);
      if (result) setAuthToken(result);
      return setLoading(false);
    } catch (error) {
      setAuthToken('');
      return setLoading(false);
    }
  }, [setLoading, authToken]);

  const memoizedValue = React.useMemo(
    () => ({
      authToken,
      setAuthToken,
      authorize,
      verify,
      loading,
      setActiveAddonDetails,
      activeAddonDetails,
      fetchedRoutes,
      vasPromotionDisable,
    }),
    [
      authToken,
      setAuthToken,
      authorize,
      verify,
      loading,
      activeAddonDetails,
      setActiveAddonDetails,
      fetchedRoutes,
      vasPromotionDisable,
    ]
  );

  return (
    <AppContext.Provider value={memoizedValue}>{children}</AppContext.Provider>
  );
}
