import axios from 'axios';
import { useQuery } from 'react-query';
import {
  AdminGetCustomersResponse,
  AdminGetStatsResponse,
  AuthorizePinCodeSuccessResult,
  ContractDetails,
} from 'types/api';
import { NavigateFunction } from 'react-router-dom';
import dayjs from 'dayjs';
import { mapContractDetails } from './detailsMapper';

export const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:3001';

export const commonHeaders = {
  'Ocp-Apim-Subscription-Key': process.env.REACT_APP_API_SUBSCRIPTION_KEY || '',
  'ngrok-skip-browser-warning': '69420',
};

const api = axios.create({ baseURL: API_URL, headers: commonHeaders });

const DATE_FORMAT = 'YYYY-MM-DD';

const formatDate = (date: Date | null) =>
  `${dayjs(date).format(DATE_FORMAT)}T00:00:00.000Z`;

export function setupInterceptors(navigate: NavigateFunction) {
  api.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error.response.status === 401 || error.response.status === 403) {
        localStorage.setItem('token', '');
        navigate('/');
      }
      throw error;
    }
  );
}

export async function authorizePin(urlCode: string, pin: string) {
  try {
    const response = await api.post<AuthorizePinCodeSuccessResult>(
      `/onboarding/authorize`,
      { url: urlCode, pincode: pin }
    );
    return response.data.result;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error({ error });
    throw error;
  }
}

export async function verifyToken(token: string) {
  try {
    const response = await api.post<string>(
      `/onboarding/test`,
      {},
      { headers: { Authorization: `Bearer ${token}` } }
    );
    return response.data;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error({ error });
    throw error;
  }
}

async function getCustomerDetails(token?: string) {
  if (
    !token &&
    window.location.pathname !== '/' &&
    !window.location.pathname.includes('admin')
  ) {
    window.location.href = '/';
    return undefined;
  }
  const response = token
    ? await api.get<ContractDetails>(`/onboarding/details`, {
        headers: { Authorization: `Bearer ${token}` },
      })
    : null;
  return response ? mapContractDetails(response.data) : undefined;
}

export function useCustomerDetailsQuery(token: string, enabled = true) {
  return useQuery(['customerDetails', token], () => getCustomerDetails(token), {
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    retry: 3,
    enabled,
  });
}

export async function addAddon(token: string, templateId?: string | number) {
  const response = await api.post<ContractDetails>(
    `/onboarding/add-addon`,
    { templateId },
    {
      headers: { Authorization: `Bearer ${token}` },
    }
  );
  return response.data;
}

export async function terminateAddon(
  token: string,
  terminateTemplateNo?: string,
  tarrifNo?: number
) {
  const response = await api.post<ContractDetails>(
    `/onboarding/terminate-addon`,
    { terminateTemplateNo, tarrifNo },
    {
      headers: { Authorization: `Bearer ${token}` },
    }
  );
  return response.data;
}

export async function joinLoyalty(token: string) {
  const response = await api.post<ContractDetails>(
    `/onboarding/join-loyalty`,
    {},
    {
      headers: { Authorization: `Bearer ${token}` },
    }
  );
  return response.data;
}

export async function requestPincode(url: string) {
  const response = await api.post<ContractDetails>(
    `/onboarding/request-pincode/${url}`,
    {}
  );
  return response.data;
}

export async function activateEInvoice(token: string, bankId: string) {
  const response = await api.post<ContractDetails>(
    `/onboarding/activate-e-invoice`,
    { bankId },
    {
      headers: { Authorization: `Bearer ${token}` },
    }
  );
  return response.data;
}

export async function submitFlow(token: string) {
  const response = await api.post<ContractDetails>(
    `/onboarding/submit`,
    {},
    {
      headers: { Authorization: `Bearer ${token}` },
    }
  );
  return response.data;
}

export async function adminLogin({
  username,
  password,
}: {
  username: string;
  password: string;
}) {
  const response = await api.post<{ token: string }>(`/admin/login`, {
    username,
    password,
  });
  return response.data;
}

export async function adminGetCustomers({
  authToken,
  order,
  orderBy,
  page,
  size,
  query = '',
  startDate = null,
  endDate = null,
}: {
  authToken: string;
  order: string;
  orderBy: string;
  page: number;
  size: number;
  query?: string;
  startDate?: Date | null;
  endDate?: Date | null;
}) {
  const startDateQuery = startDate
    ? `&startDate=${formatDate(startDate)} `
    : '';
  const endDateQuery = endDate ? `&endDate=${formatDate(endDate)}` : '';

  const response = await api.get<AdminGetCustomersResponse>(
    `/admin/get-customers?order=${order}&orderBy=${orderBy}&page=${page}&size=${size}${startDateQuery}${endDateQuery}&string=${query}`,
    {
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    }
  );
  return response.data;
}

export async function adminSendSMS({
  id,
  authToken,
}: {
  id: string;
  authToken: string;
}) {
  const response = await api.post(
    `/admin/send-sms`,
    {
      id,
    },
    {
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    }
  );
  return response.data;
}

export async function getStepsOrder() {
  const response = await api.get(`/content/steps-order`);
  return response.data;
}

export async function adminGetPreview({
  authToken,
  id,
}: {
  authToken: string;
  id?: string;
}) {
  const response = await api.get<ContractDetails>(`/admin/preview/${id}`, {
    headers: {
      Authorization: `Bearer ${authToken}`,
    },
  });
  return response ? mapContractDetails(response.data) : undefined;
}

export async function adminGetStats({ authToken }: { authToken: string }) {
  const response = await api.get<AdminGetStatsResponse>(`/admin/stats`, {
    headers: {
      Authorization: `Bearer ${authToken}`,
    },
  });
  return response ? response.data : undefined;
}

export async function adminSendBatchSMS({
  authToken,
  startDate,
  endDate,
}: {
  authToken: string;
  startDate: Date | null;
  endDate: Date | null;
}) {
  const startDateQuery = startDate ? formatDate(startDate) : '';
  const endDateQuery = endDate ? formatDate(endDate) : '';
  const response = await api.post<ContractDetails>(
    `/admin/send-batch-sms`,
    {
      startDate: startDateQuery,
      endDate: endDateQuery,
    },
    {
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    }
  );
  return response ? response.data : undefined;
}

export async function validateUrl({ url }: { url: string }) {
  return api.get(`/onboarding/validate-url/${url}`);
}

export async function changeDeliveryAddress({
  authToken,
  streetAddress,
  zipcode,
}: {
  authToken: string;
  streetAddress: string;
  zipcode: string;
}) {
  const response = await api.post(
    `/onboarding/change-delivery-address`,
    {
      zipcode,
      streetAddress,
    },
    {
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    }
  );
  return response?.data;
}

export async function verifyOrder({
  authToken,
  code,
}: {
  authToken: string;
  code: string;
}) {
  const response = await api.get(`/onboarding/order/verify?code=${code}`, {
    headers: {
      Authorization: `Bearer ${authToken}`,
    },
    withCredentials: true,
  });
  return response;
}

export async function redirectOrder({ authToken }: { authToken: string }) {
  const response = await api.get(`/onboarding/order/redirect`, {
    headers: {
      Authorization: `Bearer ${authToken}`,
    },
    withCredentials: true,
  });
  return response;
}
