import Spinner from '@components/Spinner';
import useApi from '@hooks/useApi';
import useQuery from '@hooks/useQuery';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

function AuthRedirect() {
  const query: URLSearchParams = useQuery();
  const code = query.get('code');
  const { verifyOrder } = useApi();
  const navigate = useNavigate();

  useEffect(() => {
    if (!code) navigate('/');
    else verifyOrder({ code });
  }, [code, verifyOrder, navigate]);

  return <Spinner />;
}
export default AuthRedirect;
