import {
  Box,
  ContentText,
  colors,
  fontWeights,
  BoxProps,
  ContentTextProps,
  LazyIcon,
  Link,
} from '@fortum/elemental-ui';
import FormattedEnhancedMessage from '@utils/intl-extenders/FormattedEnhancedMessage';

interface Props {
  header: string;
  content: string;
  icon?: string;
  containerProps?: BoxProps;
  contentProps?: ContentTextProps;
  onReadMoreClick?: () => void;
}

const SummaryBox: React.FC<Props> = ({
  header,
  content,
  icon,
  containerProps,
  contentProps,
  onReadMoreClick,
}) => {
  const fontColor = colors.snowWhite;
  return (
    <Box
      boxShadow="0px 0px 27px 8px rgba(0, 0, 0, 0.1)"
      display="flex"
      flexDirection="column"
      {...containerProps}
    >
      {icon ? <LazyIcon iconId={icon} color={fontColor} size={64} /> : null}
      <ContentText
        marginTop={icon ? '1rem' : '0'}
        color={fontColor}
        size="xl"
        weight={fontWeights.bold}
      >
        {header}
      </ContentText>
      <ContentText
        marginTop="1rem"
        color={fontColor}
        weight={fontWeights.light}
        {...contentProps}
      >
        {content}
      </ContentText>
      {onReadMoreClick && (
        /* eslint-disable-next-line jsx-a11y/anchor-is-valid */
        <Link
          size="xs"
          marginTop="1rem"
          color={fontColor}
          fontWeight={fontWeights.light}
          onClick={onReadMoreClick}
        >
          <FormattedEnhancedMessage i18nKey="common.readMore" />
        </Link>
      )}
    </Box>
  );
};

export default SummaryBox;
