import { ReactElement } from 'react';
import { Trans, TransProps, useTranslation } from 'react-i18next';
import getEnhancedMessageValues from './enhanced-values';

const FormattedEnhancedMessage: React.FC<TransProps<string>> = ({
  ...props
}): ReactElement<typeof Trans> => {
  const { t } = useTranslation();
  return (
    <Trans
      {...props}
      t={t}
      components={{
        ...getEnhancedMessageValues(),
        ...props.components,
      }}
    />
  );
};

export default FormattedEnhancedMessage;
