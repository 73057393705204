export interface AuthorizePinCodeSuccessResult {
  result: string;
}

export interface FailureResult {
  statusCode: number;
  message: string;
  error: string;
}

export interface PriceObject {
  unit: string;
  price: number;
  discount?: number;
  vat?: number;
  isHourlyPriced?: boolean;
  discountFrom?: string;
  discountTo?: string;
}

export type PriceKeys =
  | 'fee'
  | 'energy'
  | 'dayEnergy'
  | 'nightEnergy'
  | 'otherSeasonEnergy'
  | 'insuranceFee'
  | 'priceGuarantee'
  | 'winterDayEnergy';

export type PricesList = Partial<Record<PriceKeys, PriceObject>>;

export enum MonthlyFeeType {
  base = 'base',
  flat = 'flat',
  insurance = 'insurance',
  energy = 'energy',
}

export interface MonthlyFeePriceObject extends PriceObject {
  type: MonthlyFeeType;
}

export interface Addon {
  active: boolean;
  prices: PricesList;
  productName: string;
  tariffId: string;
  tariffNo: number;
  vapType: string;
  templateId?: string | number;
  terminateTemplateNo?: string;
}

export interface Address {
  streetName: string;
  houseNumber: string;
  houseLetter?: string;
  residence?: string;
  postalCode: string;
  postalCity: string;
  countryCode: string;
}

export enum CustomerType {
  PRIVATE = 'Private',
  BUSINESS = 'Business',
}

export interface ContractDetails {
  electricityPrice?: PriceObject;
  monthlyFee: MonthlyFeePriceObject;
  duration: {
    from: Date | string;
    to?: Date | string;
  };
  contractNumber: string;
  contractCode: string | number;
  name: string | string[];
  email: string;
  address: Address;
  meteringPointNo: string;
  meteringPointId: string;
  customerId: string | number;
  productName: string;
  activeAddons: Addon[];
  inactiveAddons: Addon[];
  recommendedAddon?: Addon;
  sunny: boolean;
  invoiceYearly: 0 | 1 | 4 | 6 | 12;
  specialElectricityPrice: {
    [key: string]: PriceObject;
  };
  customerType: CustomerType;
}

export interface AdminCustomer {
  id: string;
  contractCode: string;
  contractNo: string;
  createdAt: string;
  customerId: string;
  magicLink: { id: string; createdAt: string };
  updatedAt: string;
  customerType?: 'B2B' | 'B2C';
}

export interface AdminGetCustomersResponse {
  count: number;
  page: number;
  size: number;
  result: AdminCustomer[];
}

export interface AdminStatsObject {
  amount: string;
  date: string;
}
export interface AdminGetStatsResponse {
  customers: AdminStatsObject[];
  magicLinks: AdminStatsObject[];
}
