import useContractContext from '@hooks/useContractContext';
import Spinner from '@components/Spinner';
import B2BThankYou from '@pages/B2BThankYou';
import B2CThankYou from '@pages/B2CThankYou';
import { CustomerType } from '../../types/api';
import ErrorFallback from '../ErrorFallback';

function ThankYou() {
  const { contractDetails, isLoading, isError } = useContractContext();
  if (isError) return <ErrorFallback />;
  if (isLoading || !contractDetails) return <Spinner />;

  if (contractDetails.customerType === CustomerType.BUSINESS)
    return <B2BThankYou contractDetails={contractDetails} />;
  return <B2CThankYou />;
}

export default ThankYou;
