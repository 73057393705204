import { colors, ContentText, IconProps } from '@fortum/elemental-ui';
import BasicIconItem from './BasicIconItem';

interface FeatureIconItemProps {
  icon: string;
  label: string;
  content: string;
  iconProps?: IconProps;
}

const FeatureIconItem: React.FC<FeatureIconItemProps> = ({
  icon,
  label,
  content,
  iconProps = {},
}) => (
  <BasicIconItem
    icon={icon}
    iconProps={{ color: colors.poweringGreen, size: '3rem', ...iconProps }}
    containerProps={{ margin: '1rem 0' }}
  >
    <ContentText color={colors.inkGrey} fontWeight={300}>
      {label}
    </ContentText>
    <ContentText color={colors.inkGrey} fontWeight={400}>
      {content}
    </ContentText>
  </BasicIconItem>
);

export default FeatureIconItem;
