import { Box, Button, FortumLogo, InputField } from '@fortum/elemental-ui';
import useAdminContext from '@hooks/useAdminContext';
import { ChangeEvent, FormEvent, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';

const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  padding: 2rem;
  width: 50%;
`;

function AdminLogin() {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const { authorizeAdmin } = useAdminContext();
  const navigate = useNavigate();
  const { state } = useLocation();

  function onUsernameChange(e: ChangeEvent<HTMLInputElement>) {
    setUsername(e.target.value);
  }

  function onPasswordChange(e: ChangeEvent<HTMLInputElement>) {
    setPassword(e.target.value);
  }

  async function onLoginClick(e: FormEvent<HTMLFormElement>) {
    e.preventDefault();
    const result = await authorizeAdmin({ username, password });

    if (result)
      navigate((state as { from?: string })?.from || '/admin/dashboard');
  }

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      padding="2rem"
    >
      <FortumLogo />
      <StyledForm onSubmit={onLoginClick}>
        <InputField
          name="username"
          marginBottom="1rem"
          label="Username"
          onChange={onUsernameChange}
          value={username}
        />
        <InputField
          name="password"
          marginBottom="1rem"
          type="password"
          label="Password"
          onChange={onPasswordChange}
          value={password}
        />
        <Button type="submit">Log in</Button>
      </StyledForm>
    </Box>
  );
}

export default AdminLogin;
