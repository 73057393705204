import { Checkbox, ContentText, Link } from '@fortum/elemental-ui';
import { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

const StyledCheckbox = styled(Checkbox)`
  label {
    font-size: 1rem;
  }
`;

interface IProps {
  checked: boolean;
  onChange: Dispatch<SetStateAction<boolean>>;
  isB2B?: boolean;
}

const ContractTerms: React.FC<IProps> = ({
  checked,
  onChange,
  isB2B = false,
}) => {
  const { t } = useTranslation();

  const prefix = isB2B ? 'B2B_' : '';

  return (
    <>
      <ContentText margin="1rem 0 0.5rem">
        {t(`contractTerms.${prefix}text`, {
          termslink: t(`contractTerms.${prefix}link`),
        })}
        <Link href={t(`contractTerms.${prefix}link.url`)}>
          {t(`contractTerms.${prefix}link.text`)}
        </Link>
      </ContentText>
      <StyledCheckbox
        marginBottom="1rem"
        label={t(`contractTerms.${prefix}check`)}
        value="contractTerms"
        name="contractTerms"
        fontSize="1rem"
        checked={checked}
        onChange={(e) => onChange(e.target.checked)}
      />
    </>
  );
};

export default ContractTerms;
