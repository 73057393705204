import {
  Box,
  colors,
  ContentText,
  Link,
  Toggle,
  fontWeights,
  LazyIcon,
  Button,
  IconCheck,
} from '@fortum/elemental-ui';
import FormattedEnhancedMessage from '@utils/intl-extenders/FormattedEnhancedMessage';
import {
  getLastPriceObject,
  getProperPriceString,
  hasDiscount,
} from '@utils/prices';
import dayjs from 'dayjs';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Addon, PricesList } from 'types/api';

interface ItemProps {
  addon: Addon;
  active?: boolean;
  onReadMoreClick?: () => void;
  onSwitchChange?: () => void;
  readOnly?: boolean;
  disabled?: boolean;
}

const ReadMoreLink = ({ onClick }: { onClick?: () => void }) => (
  // eslint-disable-next-line jsx-a11y/anchor-is-valid
  <Link size="xs" color={colors.emperorGrey} onClick={onClick}>
    <FormattedEnhancedMessage i18nKey="common.readMore" />
  </Link>
);

const VasCard: React.FC<ItemProps> = ({
  addon,
  active,
  onReadMoreClick,
  onSwitchChange,
  readOnly,
  disabled = false,
}) => {
  const [value, setValue] = useState(active);
  const { i18n, t } = useTranslation();
  const icon = i18n.exists(`addons.${addon.tariffId}.icon`)
    ? t(`addons.${addon.tariffId}.icon`)
    : null;
  const extraPriceText = i18n.exists(`addons.${addon.tariffId}.extraPriceText`)
    ? t(`addons.${addon.tariffId}.extraPriceText`)
    : null;

  const basePrice = getProperPriceString(addon.prices, t);
  const priceAfterDiscount = getProperPriceString(addon.prices, t, true);

  const hasDiscountValue = hasDiscount(addon.prices);
  const priceObject = getLastPriceObject(addon.prices as PricesList);

  const discountTo =
    hasDiscountValue && priceObject.discountTo
      ? dayjs(priceObject.discountTo).format('D.MM.YYYY')
      : null;

  return (
    <Box padding="1rem" border={`1px solid ${colors.fogGrey}`} margin="1rem 0">
      <Box display="flex" alignItems="center">
        {icon ? (
          <LazyIcon iconId={icon} color={colors.oceanGreen} size="3rem" />
        ) : null}
        <Box marginLeft={icon ? '1rem' : 0}>
          <ContentText size="xl" color={colors.inkGrey}>
            {t(`addons.${addon.tariffId}.title`)}
          </ContentText>
        </Box>
      </Box>
      <ContentText
        marginTop="1rem"
        color={colors.inkGrey}
        fontWeight={fontWeights.light}
        lineHeight={1.7}
      >
        {t(
          `addons.${addon.tariffId}.shortDescription${active ? 'Chosen' : ''}`
        )}
      </ContentText>
      <ReadMoreLink onClick={onReadMoreClick} />
      <Box
        display="flex"
        marginTop="1rem"
        justifyContent="space-between"
        alignItems="flex-end"
      >
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
          paddingRight="1rem"
        >
          <ContentText color={colors.oceanGreen} size="l">
            {hasDiscountValue ? priceAfterDiscount : basePrice}
          </ContentText>
          {hasDiscountValue && (
            <>
              <ContentText
                style={{ textDecorationLine: 'line-through' }}
                color={colors.octoberGrey}
                size="l"
              >
                {basePrice}
              </ContentText>
              <ContentText size="xs" color={colors.oceanGreen}>
                {discountTo
                  ? t('invoice.discountValidity', { to: discountTo })
                  : t('invoice.discountNoValidTo')}
              </ContentText>
            </>
          )}
          {extraPriceText && (
            <ContentText size="xs" color={colors.oceanGreen}>
              {extraPriceText}
            </ContentText>
          )}
        </Box>
        {readOnly ? (
          <Button
            leftIcon={<IconCheck marginRight="-4px" />}
            color={colors.snowWhite}
            textColor={colors.snowWhite}
            backgroundColor={colors.fogGrey}
            borderRadius="1rem"
            fontSize="1rem"
            padding="6.5px 16px"
            height="35px"
          >
            {t('common.active')}
          </Button>
        ) : (
          <Toggle
            checked={value}
            name={addon.tariffId}
            height="2rem"
            disabled={disabled}
            onChange={() => {
              if (!onSwitchChange) return;
              onSwitchChange();
              setValue((prev) => !prev);
            }}
          />
        )}
      </Box>
    </Box>
  );
};

export default VasCard;
