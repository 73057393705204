import { Box, colors } from '@fortum/elemental-ui';

const Divider = () => {
  return (
    <Box
      backgroundColor={colors.emperorGrey}
      border="0 solid #555555"
      height="0.5px"
      width="100%"
      opacity={0.2}
      data-testid="divider"
    />
  );
};

export default Divider;
