import DateRangeFilter from '@components/DateRangeFilter';
import { Box, Button, ContentText } from '@fortum/elemental-ui';
import useAdminContext from '@hooks/useAdminContext';
import useNotificationContext from '@hooks/useNotificationContext';
import { adminSendBatchSMS } from '@utils/API';
import { AxiosError } from 'axios';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

function BatchSmsSending() {
  const [dateFrom, setDateFrom] = useState<Date | null>(null);
  const [dateTo, setDateTo] = useState<Date | null>(null);
  const { authToken, logout } = useAdminContext();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const { setNotification } = useNotificationContext();

  async function onClick() {
    try {
      setLoading(true);
      await adminSendBatchSMS({
        authToken,
        startDate: dateFrom,
        endDate: dateTo,
      });
      setNotification({ text: 'SMSes sent', visible: true });
    } catch (error) {
      if (
        error instanceof AxiosError &&
        (error.response?.status === 403 || error.response?.status === 401)
      ) {
        logout();
        navigate('/admin/login');
      }
    } finally {
      setLoading(false);
    }
  }

  return (
    <Box width="100%" mb="1rem">
      <ContentText size="xl" marginBottom="2rem" textAlign="left">
        Batch SMS Sending
      </ContentText>
      <Box width="fit-content">
        <DateRangeFilter
          fromValue={dateFrom}
          onFromChange={(from) => setDateFrom(from)}
          toValue={dateTo}
          onToChange={(to) => setDateTo(to)}
        />
      </Box>
      <Button
        disabled={loading || !dateFrom || !dateTo}
        mt="1rem"
        onClick={onClick}
        marginRight="0.5rem"
      >
        Send
      </Button>
    </Box>
  );
}

export default BatchSmsSending;
