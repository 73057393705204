import { colors, ContentText, Link } from '@fortum/elemental-ui';
import FormattedEnhancedMessage from '@utils/intl-extenders/FormattedEnhancedMessage';
import { useTranslation } from 'react-i18next';

const Consents = ({ prefix = '' }) => {
  const { t } = useTranslation();

  return (
    <ContentText color={colors.emperorGrey} size="xs" data-testid="consents">
      <FormattedEnhancedMessage i18nKey={`consents.${prefix}mainText`} />
      <Link href={t(`consents.${prefix}link.url`)} size="xs">
        <FormattedEnhancedMessage i18nKey={`consents.${prefix}link.text`} />
      </Link>
    </ContentText>
  );
};

export default Consents;
