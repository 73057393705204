import {
  ContractSummary,
  RecommendedVas,
  LoyaltyProgram,
  Invoice,
  ThankYou,
  LandingPage,
  FAQBills,
  AddressChanged,
} from '@pages/index';
import { RouteObject } from 'react-router-dom';

export type RenderedRoute = {
  component: () => JSX.Element;
  unprotected?: boolean;
  order?: number;
  name?: string;
  previousRoute?: string;
  shouldRender?: boolean;
  id?: string;
} & RouteObject;

export const additionalRoutes: RenderedRoute[] = [
  {
    path: '/',
    component: LandingPage,
    unprotected: true,
  },
  {
    path: '/bills-faq',
    component: FAQBills,
  },
  {
    path: '/address-changed',
    order: 1,
    component: AddressChanged,
  },
];

export const mainFlowRoutes: RenderedRoute[] = [
  {
    name: 'navigation.step.yourContract',
    path: '/contract-summary',
    order: 1,
    component: ContractSummary,
  },
  {
    name: 'navigation.step.yourServices',
    path: '/recommended-vas',
    order: 2,
    component: RecommendedVas,
  },
  {
    name: 'navigation.step.loyaltyProgram',
    path: '/loyalty-program',
    order: 2,
    component: LoyaltyProgram,
  },
  {
    name: 'navigation.step.yourInvoices',
    path: '/your-invoices',
    order: 3,
    component: Invoice,
  },
  {
    name: 'navigation.step.thankYou',
    path: '/thank-you',
    order: 4,
    component: ThankYou,
  },
];

export const getMainFlowRoutes = (customRoutes: RenderedRoute[]) => [
  {
    name: 'navigation.step.yourContract',
    path: '/contract-summary',
    order: 1,
    component: ContractSummary,
    shouldRender: true,
  },
  ...customRoutes,
  {
    name: 'navigation.step.yourInvoices',
    path: '/your-invoices',
    order: 3,
    component: Invoice,
    shouldRender: true,
  },
  {
    name: 'navigation.step.thankYou',
    path: '/thank-you',
    order: 4,
    component: ThankYou,
    previousRoute: '/your-invoices',
    shouldRender: true,
  },
];
