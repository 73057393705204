import { colors, ContentText, IconProps, Col, Box } from '@fortum/elemental-ui';

interface Props {
  label: string;
  icon?: React.ReactElement<IconProps>;
  children: React.ReactNode;
}

const KeyValueColumn: React.FC<Props> = ({ label, icon = null, children }) => {
  return (
    <Box display="flex" width="100%" margin="0 0 0.75rem">
      <Col display="flex" width="auto" alignItems="flex-start">
        {icon}
      </Col>
      <Col width="auto" align-items="flex-start">
        <ContentText color={colors.emperorGrey} size="xs">
          {label}
        </ContentText>
        <ContentText
          color={colors.inkGrey}
          display="flex"
          alignItems="flex-start"
          style={{ wordBreak: 'break-word' }}
        >
          {children}
        </ContentText>
      </Col>
    </Box>
  );
};

export default KeyValueColumn;
