import {
  ForwardLink,
  Link,
  Button,
  CommonForwardLinkProps,
  LinkProps,
  CommonButtonProps,
  BoxProps,
} from '@fortum/elemental-ui';
import React, { AnchorHTMLAttributes, DetailedHTMLProps } from 'react';
import { useTranslation } from 'react-i18next';

type ContentfulKeyProps = {
  contentfulKey?: string;
  children?: React.ReactNode;
};

type ButtonProps = CommonButtonProps & BoxProps;

const useDataGtm = (contentfulKey?: string) => {
  const { t } = useTranslation();
  const labelFromContentful = contentfulKey ? t(contentfulKey) : '';

  const gtmEnabled = t(`${contentfulKey}.gtmEnabled`) === 'true';
  return [gtmEnabled, labelFromContentful];
};

function withDataGtm<P>(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  Component: React.ComponentType<P & any>
): React.FC<P & ContentfulKeyProps> {
  return (props) => {
    // eslint-disable-next-line react/destructuring-assignment
    const { children, contentfulKey, ...rest } = props;
    const [gtmEnabled, labelFromContentful] = useDataGtm(contentfulKey);
    return (
      <Component data-gtm={gtmEnabled} {...rest}>
        {children || labelFromContentful}
      </Component>
    );
  };
}

const AComponent: React.FC<{ children?: React.ReactNode }> = ({
  children,
  ...rest
}) => <a {...rest}>{children}</a>;

const GTMLink = withDataGtm<LinkProps>(Link);
const GTMButton = withDataGtm<ButtonProps>(Button);
const GTMForwardLink = withDataGtm<CommonForwardLinkProps>(ForwardLink);
const GTMA =
  withDataGtm<
    DetailedHTMLProps<
      AnchorHTMLAttributes<HTMLAnchorElement>,
      HTMLAnchorElement
    >
  >(AComponent);

export { GTMLink, GTMButton, GTMForwardLink, GTMA };
