import { Loader, Overlay } from '@fortum/elemental-ui';

interface Props {
  overlay?: boolean;
}

const Spinner = ({ overlay = false }: Props) => {
  return (
    <>
      {/* eslint-disable-next-line @typescript-eslint/no-empty-function */}
      {overlay ? <Overlay opened onClose={() => {}} /> : null}
      <Loader
        data-test="Application-Loader"
        position="absolute"
        left="50%"
        top="50%"
      />
    </>
  );
};

export default Spinner;
