import { Box, colors, ContentText } from '@fortum/elemental-ui';
import { getProperPriceString, hasDiscount } from '@utils/prices';
import { useTranslation } from 'react-i18next';
import { PriceObject } from 'types/api';

interface Props {
  label: string;
  priceObject: PriceObject;
}

const KeyValuePrice: React.FC<Props> = ({ label, priceObject }) => {
  const { t } = useTranslation();

  const basePrice = getProperPriceString(priceObject, t);
  const priceAfterDiscount = getProperPriceString(priceObject, t, true);
  const hasDiscountValue = hasDiscount(priceObject);

  if (!basePrice) return null;

  return (
    <Box
      display="flex"
      justifyContent="space-between"
      width="100%"
      alignItems="center"
      margin="0"
      marginBottom="0.5rem"
    >
      <ContentText color={colors.emperorGrey} size="s">
        {label}
      </ContentText>
      <Box display="flex" flexDirection="row" alignItems="flex-end">
        {hasDiscountValue ? (
          <ContentText
            color={colors.inkGrey}
            display="flex"
            size="xs"
            style={{ textDecoration: 'line-through' }}
            marginRight="0.5rem"
          >
            {basePrice}
          </ContentText>
        ) : null}
        <ContentText color={colors.inkGrey} display="flex">
          {hasDiscountValue ? priceAfterDiscount : basePrice}
        </ContentText>
      </Box>
    </Box>
  );
};

export default KeyValuePrice;
